.footer-contact{
	padding: 30px 0px;
	background-color: $color-1st;
	color: #fff;
	font-size: rem-calc(18);
	font-weight: $font-medium;
	margin-top: 30px;
	.dktv{
		width: 450px;
		display: flex;
		margin-left: 30px;
		.form-group{
			width: 100%;
			margin: 0px;
			.form-control{
				padding: 10px 15px;
				outline: none !important;
				background-color: #fff;
				font-size: rem-calc(14);
				border: none;
				box-shadow: none;
				border-radius: 0px;
				width: 100%;
				&:focus{
					outline: none !important;
					border: none;
					box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.09), 0 4px 15px 0 rgba(0, 0, 0, 0.05);
				}
			}
		}
		.btn-contact{
			display: inline-table;
			color: #fff;
			background-color: $color-4st;
			font-weight: $font-bold;
			font-size: rem-calc(16);
			font-weight: $font-regular;
			border-radius: 0px;
			outline: none;
			border: none;
			cursor: pointer;
			white-space: nowrap;
			padding: 0px 15px;
			&:focus{
				outline: none;
				box-shadow: none;
			}
			&:hover{
				background-color: $color-4st;
				color: #fff;
			}
		}
	}
}
@media(max-width: 1169px){
	.footer-contact{
		.dktv{
			width: 100%;
			margin-top: 15px;
			margin-left: 0px;
		}
	}
}