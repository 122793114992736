@media (max-width: 991px){
	header{
		// padding-top: 50px;
	}
	.header-bar{
		display: none;
	}
	.header-mid{
		display: none;
	}
}

// @media (min-width: 768px){
// }

@media (min-width: 992px){
	.button-res{
		display: none;
	}
}

// @media (min-width: 1200px){
	
// }