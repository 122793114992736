.br-crumb{
	background-color: #fff;
	border-top: 1px solid rgba(0,0,0,.05);
	.breadcrumb{
		margin-bottom: 15px;
		background-color: #fff;
		padding: 15px 0px;
		border-radius: 0px;
		li{
			font-weight: 400;
			font-size: rem-calc(16);
			color: $color-2st;
			text-decoration: underline;
			a{
			    color: $color-2st;
			    text-decoration: underline;
			    transition: color 0.2s ease-in-out 0s;
			    &:hover{
					color: $color-2st;
					text-decoration: none;
				}
			}
		}
	}
}