.pro-detail{}
.wrap-pro-detail{
	padding-bottom: 15px;
	.pro-detail-img{
		width: 50%;
		img{
			border-radius: 15px;
		}
	}
	.pro-detail_content{
		width: 50%;
		width: calc(50% - 30px);
		margin-left: 30px;
		.pro-props__name{
			font-size: 26px;
			font-weight: $font-medium;
		}
		.pro-props{
			margin: 5px 0px;
			.pro-props__price{
			    font-size: 22px;
			    font-weight: 700;
			    color: $color-3st;
			    display: inline-block;
			}
			.pro-props__oldprice{
			    text-decoration: line-through;
			    color: gray;
			    font-size: 16px;
			    display: inline-block;
			}
			// .pro-props__discounts{
			// 	color: $color-4st;
			// 	display: inline-table;
			// 	padding-left: 5px;
			// }
		}
		// .masanpham{
		// 	padding:0px;
		// 	margin:0px;
		// 	list-style: none;
		// 	li{
		// 		font-size: 14px;
		// 		margin-right: 5px;
		// 		border-right: 1px solid rgba(0,0,0,.2);
		// 		padding: 0px 15px;
		// 		&:first-child{
		// 			padding-left: 0px;
		// 		}
		// 		&:last-child{
		// 			padding-right: 0px;
		// 			color: $color-6st;
		// 			border-right: none;
		// 		}
		// 	}
		// }
		.pro-props__attr{
		    border: 1px dashed $color-2st;
		    border-radius: 15px;
		    background-color: #fff;
		    padding: 15px;
		    .tit{
		    	color: $color-2st;
		    	font-size: rem-calc(22);
		    	font-weight: $font-medium;
		    }
		    ul{
		    	padding: 0px;
		    	margin: 0px;
		    	list-style: none;
		    	li{
					display: inline-table;
					width: 100%;
				}
		    }
		}
		// .youtube-popup{
		// 	display: inline-table;
		// 	border: 1px solid $color-2st;
		// 	padding: 5px 15px;
		// 	margin: 10px 0px;
		// 	border-radius: 3px;
		// 	color: $color-2st;
		// }
		.pro-property{
			margin-top: 15px;
			// .property-item{
			// 	width: 50px;
			// 	margin-right: 35px;
			// 	.pro-quantity{
			// 	    display: inline-block;
			// 	    position: relative;

			// 	    .input-number{
			// 		    line-height: 39px;
			// 		    margin: 0;
			// 		    display: inline-block;
			// 		    width: 100px;
			// 		    padding-right: 0;
			// 		    height: 38px;
			// 		    width: 40px;
			// 		    margin-left: 20px;
			// 		    border: 1px solid $color-4st;
			// 		    border-radius: 3px;
			// 		    text-align: center;
			// 		}

			// 	    .input-number-decrement{
			// 	    	position: absolute;
			// 	    	text-align: center;
			// 		    right: 40px;
			// 		    top: 0px;
			// 		    width: 30px;
			// 		    height: 38px;
			// 		    cursor: pointer;
			// 		    display: block;
			// 		    line-height: 38px;
			// 		    font-size: rem-calc(16);
			// 		}
			// 		.input-number-increment{
			// 			position: absolute;
			// 			text-align: center;
			// 		    right: -30px;
			// 		    top: 0px;
			// 		    width: 30px;
			// 		    height: 38px;
			// 		    cursor: pointer;
			// 		    display: block;
			// 		    line-height: 38px;
			// 		    font-size: rem-calc(16);
			// 		}
			// 	}
			// }
			// .buy-now{
			// 	.btn-muangay{
			// 		background-color: $color-4st;
			// 		text-transform: uppercase;
			// 		color: #fff;
			// 		border: none;
			// 		display: inline-block;
			// 		padding: 8px 15px;
			// 		font-weight: $font-bold;
			// 		margin-left: 5px;
			// 		border-radius: 3px;
			// 		cursor: pointer;
			// 		&:hover{
			// 			background-color: $color-2st;
			// 			color: #fff;
			// 		}
			// 		&:last-child{
			// 			background-color: $color-6st;
			// 		}
			// 	}
			// }
		}
	}

	.pro-detail-other{
		width: 20%;
		display: inline-block;
		min-height: 100%;
		// ul{
		// 	padding: 0px;
		// 	margin: 0px;
		// 	list-style: none;
		// 	li{
		// 		display: inline-table;
		// 		width: 100%;
		// 		border-bottom: 1px solid #ddd;
		// 		padding-top: 10px;
		// 		&:last-child{
		// 			border-bottom: none;
		// 		}
		// 	}
		// }
	}

	@media(max-width: 991px){
		.pro-detail-img{
			width: 100%;
		}
		.pro-detail_content{
			width: 100%;
			margin-left: 0px;
			border: none;
			padding: 0px;
			padding-top: 15px;
		}
		.pro-detail-other{
			width: 100%;
			border: none;
			padding: 15px 10px;
			border: 1px solid #ddd;
			border-radius: 10px;
			margin: 15px 0px;
		}
	}
}

.chuongtrinhtour{
	.card{
		margin-bottom: 15px;
		.card-header{
			font-size: rem-calc(22);
			font-weight: $font-medium;
			a{
				color: blue;
				display: inline-block;
			}
			.gia{
				display: inline-block;
				color: $color-2st;
			}
		}
	}
}

// .pro-gioithieu,
// .pro-tskt,
// .pro-commnet{
// 	padding-top: 30px;
// }
// .pro-gioithieu{
// 	.tab-detail{
// 		li{
// 			a{
// 				font-size: rem-calc(18);
// 				text-transform: capitalize;
// 				color: $color-2st;
// 				font-weight: $font-bold;
// 			}
// 		}
// 	}
// }
// .title-pro-meta{
// 	display: inline-table;
// 	width: 100%;
// 	font-size: 22px;
// 	font-weight: 700;
// 	color: #000;
// 	// position: relative;
// 	padding-bottom: 10px;
// 	margin: 15px 0px;
// 	margin-top: 15px;
// 	border-bottom: 1px solid #ddd;

// 	// &:before{
// 	// 	position: absolute;
// 	// 	width: 50px;
// 	// 	height: 2px;
// 	// 	background-color: #000;
// 	// 	bottom: 0px;
// 	// 	left: 0px;
// 	// 	content: "";
// 	// }
// }

@media(max-width: 767px){
	// .pro-gioithieu{
	// 	.tab-detail{
	// 		flex-wrap: nowrap;
	// 		overflow-x: scroll;
	// 		li{
	// 			text-align: center;
	// 			a{
	// 				font-size: rem-calc(18);
	// 				text-transform: capitalize;
	// 				font-size: rem-calc(16);
	// 			}
	// 		}
	// 	}
	// }
	@media(max-width: 576px){
		.wrap-pro-detail{
			.pro-detail_content{
				// .pro-property{
				// 	.property-item{
				// 		width: 100%;
				// 		margin-bottom: 10px;
				// 	}
				// 	// .buy-now{
				// 	// 	.btn-muangay{
				// 	// 		margin-top: 10px;
				// 	// 		width: 50%;
				// 	// 		width: calc(50% - 2.5px);
				// 	// 		margin-right: 5px;
				// 	// 		display: block;
				// 	// 		margin-left: 0px;
				// 	// 		padding: 5px 0px;
				// 	// 		text-align: center;
				// 	// 		float: left;
				// 	// 		&:last-child{
				// 	// 			margin-right: 0px;
				// 	// 		}
				// 	// 	}
				// 	// }
				// }
			}
		} 
	}
}

// .pro-property{
// 	.btn-muangay{
// 		content: "";
// 		position: absolute;
// 		bottom: 0px;
// 		z-index: 999;
// 	}
// }