.slider-categories{
	.menu-prduct-home{
		width: 25%;
		background-color: #f4f4f4;
		.menu-pro{
			position: static;
			visibility: visible;
			opacity: 1;
			display: block;
			list-style: none;
			padding: 0px;
			margin: 0px;
		}
	}
	.slider{
		width: 75%;
		// width: calc(75% - 5px);
		// margin-top: 5px;
		// margin-left: 5px;
	}
	// .qc-home{
	// 	width: 25%;
	// 	margin-top: 10px;
	// 	.item{
	// 		margin-bottom: 10px;
	// 		display: inline-block;
	// 		width: 100%;
	// 		img{
	// 			width: 100%;
	// 			max-width: 100%;
	// 		}
	// 		&:last-child{
	// 			margin-bottom: 0px;
	// 		}
	// 	}
	// }
}
@media(max-width: 992px){
	.slider-categories{
		// .container{
		// 	padding-left: 0px;
		// 	padding-right: 0px;
		// }
		.menu-prduct-home{
			display: none;
		}
		.slider{
			width: 100%;
			width: calc(100% - 0px);
			margin: 0px;
		}
		// .qc-home{
		// 	width: 100%;
		// 	margin-top: 10px;
		// 	.item{
		// 		margin-bottom: 0px;
		// 		width: 50%;
		// 		width: calc(50% - 5px);
		// 		margin-right: 10px;
		// 		&:last-child{
		// 			margin-right: 0px;
		// 		}
		// 	}
		// }
	}
}

