.menu-pro{
	display: inline-table;
	width: 100%;
	top: 100%;
	position: absolute;
	left: 0px;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	display: none;
	list-style: none;
	margin: 0px;
	padding: 0px;
	background-color: #f4f4f4;
	li{
		width: 100%;
		line-height: 18px;
		float: left;
		position: relative;
		a{
			color: $color-1st;
			padding: 5px 15px;
			display: inline-table;
			width: 100%;
			position: relative;
			text-transform: none;
			font-size: rem-calc(14);
			.fa-angle-right{
				position: absolute;
				right: 15px;
				content: "";
				font-size: rem-calc(20);
				color: $color-2st;
			}
			&:hover{
				background-color: #f4f4f4;
				color: $color-2st;
			}
		}
		ul{
			position: absolute;
			content: "";
			left: 100%;
			top: 0px;
			width: 250px;
			min-height: 100%;
			height: auto;
			background-color: #fff;
			z-index: 999;
			border-left: none;
			display: none;
			visibility: hidden;
			opacity: 0;
			list-style: none;
			padding: 15px 0px;
			margin: 0px;
			li{
				width: 100%;
				float: left;
				a{
					color: $color-1st;
					font-weight: $font-regular;
					font-size: rem-calc(14);
					// &:hover{
					// 	color: $color-2st;
					// }
				}
				// ul{
				// 	position: static;
				// 	display: block;
				// 	visibility: visible;
				// 	opacity: 1;
				// 	width: 200px;
				// 	list-style: none;
				// 	padding: 0px;
				// 	margin: 0px;
				// 	border: none;
				// 	li{
				// 		width: 100%;
				// 		a{
				// 			color: $color-1st;
				// 			padding: 5px 15px;
				// 			font-size: rem-calc(12);
				// 			font-weight: $font-regular;
				// 		}
				// 	}
				// }
			}
		}
		&:hover{
			ul{
				display: block;
				visibility: visible;
				opacity: 1;
			}
		}
		&:last-child{
			a{
				border-bottom: none;
			}
		}
	}
}