.bottom-footer{
	padding: 15px 0px;
	text-align: center;
	background-color: #fff;
	a{
		color: $color-2st;
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}
