body{
	max-width: 100%;
	overflow-x: hidden;
	font-size: rem-calc(16);
	background-color: #fff;
	color: #333;
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: $font-regular;
}
img{
	max-width: 100%;
}
a{
	color: $color-2st;
	text-decoration: none;
	&:hover{
		color: $color-1st;
		text-decoration: none;
	}
}
///////////////////////////////////////////
// mobile
@media (max-width: 767px){
	header {
	    border-bottom-color: white; //rgb(255, 255, 255)
	}
}

//table
@media (min-width: 768px){
	header {
	    border-bottom-color: red; //rgb(255, 0, 0)
	}
}
//computer
@media (min-width: 992px){
	header {
	    border-bottom-color: yellow; //rgb(255, 255, 0)
	}
}