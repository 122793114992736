.pro-cate{
    padding-top: 10px;
}
.pro-cate--it{
    width: 33.33%;
    width: calc(33.33% - 10px);
    margin-right: 15px;
    margin-bottom: 15px;
    display: inline-table;
    border: 1px solid rgba(0,0,0,.2);
    padding: 5px;
    ._img{
        display: inline-table;
        height: 150px;
        display: flex;
        justify-content: center;
        img{
            max-width: 100%;
            opacity: 1;
        }
    }
    .name{
        display: inline-table;
        width: 100%;
        padding: 10px 0px;
        font-size: 18px;
        color: $color-1st;
    }
    &:hover{
        img{
            opacity: .8;
        }
    }
    &:nth-child(3n +3){
        margin-right: 0px;
    }
}

@media(max-width: 767px){
    .pro-cate--it{
        width: 50%;
        width: calc(50% - 10px);
        &:nth-child(3n + 3){
            margin-right: 15px;
        }
        &:nth-child(2n + 2){
            margin-right: 0px;
        }
    }
}