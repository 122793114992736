.intro-home{
	padding: 15px 0px;
	.line{
		width: 100%;
		height: 1px;
		background-color: rgba(0,0,0,.1);
		// padding-bottom: 15px;
	}
	h1{
		text-transform: capitalize;
		color: $color-2st;
		font-size: rem-calc(24);
		padding-top: 15px;
	}
}