//Custom variables

// Descriptive color variables

// Functional color variables
$color-1st : #000;

//xanh green 
$color-2st : #008000;

//red
$color-3st : #d82727;

//yellow
$color-4st : #ebe829;

//yellow
$color-5st : #fab914;

//GREEN
$color-6st : #008f00;
// $color-5st : #272727;
// Font stacks
// $font-1st : 'font-family: 'Roboto', sans-serif;';

// $font-light: normal;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
// $font-supperbold: bold;

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';
