.new-home{
	padding-top: 30px;
}
// tin ngang
.new-horizontal{
	width: 100%;
	&--item{
		width: 100%;
		float: left;
		padding: 10px 0px;
		&__img{
			width: 30%;
			display: inline-block;
			float: left;
			img{
				width: 100%;
				max-width: 100%;
				border-radius: 5px;
			}
		}
		&__content{
			float: left;
			width: 70%;
			width: calc(70% - 30px);
			margin-left: 30px;
			display: inline-block;
			@include headings{
				a{
					color: $color-2st;
					font-size: rem-calc(22);
					&:hover{
						color: $color-1st;
					}
				}
			}
			.readmore{
				display: inline-block;
				padding: 5px 15px;
				background-color: $color-2st;
				color: #fff;
				border-radius: 3px;
			}
		}
		&:nth-child(2n + 2){
			margin-right: 0px;
		}
	}
}
// @media(max-width: 767px){
// 	.new-horizontal{
// 		margin-bottom: 15px;
// 		&--item{
// 			width: 100%;
// 			width: calc(100% - 0px);
// 			margin-right: 0px;
// 		}
// 	}
// }
//End tin ngang

// Tin dọc
.new-vertical{
	&--item{
		width: 100%;
		display: inline-block;
		margin-bottom: 30px;
		&__img{
			width: 100%;
			display: inline-block;
			position: relative;
			overflow: hidden;
			a{
				display: block;
				img{
					width: 100%;
					max-width: 100%;
					opacity: .9;
					border-radius: 15px;
				}
				&:hover{
					img{
						opacity: 1;
					}
				}
			}
		}
		&__content{
			width: 100%;
			width: calc(100% - 0px);
			display: inline-block;
			padding-top: 15px;
			@include headings{
				padding: 0px;
				margin: 0px;
				font-size: rem-calc(22);
				display: inline-block;
				width: 100%;
				a{
					color: $color-2st;
					&:hover{
						color: $color-1st;
					}
				}
			}
		}
		&:hover{
			img{
				-webkit-transform: rotate(0) scale(1);
				transform: rotate(0) scale(1);
			}
		}
	}
}
@media(min-width: 991px){
	.li-2{
		.new-vertical{
			&--item{
				width: 50%;
				width: calc(50% - 15px);
				margin-right: 30px;
				&__content{
					@include headings{
						font-size: rem-calc(18);
					}
				}
				&:nth-child(2n + 2){
					margin-right: 0px;
				}
			}
		}
	}
}
// end tin dọc

.list-tin{
	padding: 0px;
	margin: 0px;
	list-style: none;
	li{
		display: inline-block;
		width: 100%;
		padding-bottom: 10px;
		a{
			font-size: rem-calc(16);
			position: relative;
			padding-left: 20px;
			color: $color-2st;
			&:before{
				content: "";
				position: absolute;
				left: 0px;
				width: 5px;
				height: 5px;
				background-color: $color-2st;
				top: 5px;
				border-radius: 100%;
			}
			&:hover{
				color: $color-4st;
			}
		}
		
	}
}