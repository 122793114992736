.header-top{
	background-color: $color-2st;
	padding: 5px 0px;
	font-size: rem-calc(14);
	.top-list{
		padding: 0px;
		margin: 0px;
		list-style: none;
		li{
			display: inline-block;
			line-height: 20px;
			font-weight: $font-regular;
			color: #fff;
			border-right: 1px solid #fff;
			padding-right: 15px;
			margin-right: 15px;
			a{
				color: #fff;
			}
			i{
				margin-right: 5px;
			}
			&:last-child{
				padding-right: 0px;
				margint-right: 0px;
				border-right: none;
			}
		}
	}
}
@media (max-width: 992px){
	.header-top{
		text-align: center;
		.pull-right{
			float: none;
		}
	}
}
