.title-main {
	margin-bottom: 30px;
	border-bottom: 1px solid rgba(0,0,0,.1);
	@include headings{
		font-weight: $font-bold;
		font-size: rem-calc(26);
		display: inline-block;
		padding-bottom: 5px;
		margin: 0px;
		position: relative;
		&:before{
			content:"";
			position: absolute;
			left: 0px;
			bottom: 0px;
			width: 50px;
			height: 3px;
			background-color: $color-2st;
		}
		a{
			color: #fff;
		}
	}
}

.list-service{
	padding: 0px 15px;
	padding-right: 0px;
	margin: 0px;
	list-style: none;
	display: inline-block;
	float: right;
	li{
		float: left;
		a{
			color: $color-2st;
			line-height: 45px;
			// padding: 0px 15px;
			&:hover{
				color: $color-1st;
			}
		}
		// &:last-child a{
		// 	border-right: none;
		// 	color: $color-4st;
		// 	padding-right: 0px;
		// }
	}
}
// .title-new{
// 	margin-bottom: 15px;
// 	position: relative;
// 	@include headings{
// 		font-weight: 500;
// 		font-size: rem-calc(24);
// 		color: $color-1st;
// 		margin: 0px;
// 	}
// }

// .price_search_form{
// 	display: inline-table;
// 	width: 100%;
// 	margin-top: 30px;
// 	background-color: #f4f4f4;
// 	padding: 15px;
// 	border-radius: 3px;

// 	display: flex;
// 	flex-wrap: wrap;
// 	.form-control{
// 		width: 50%;
// 		width: calc(50% - 7.5px);
// 		margin-right: 15px;

// 		border: 1px solid #ddd;
// 		outline: none;
// 		border-radius: 3px;
// 		font-size: rem-calc(14);
// 		&:nth-child(2n + 2){
// 			margin-right: 0px;
// 		}
// 	}
// 	.btn{
// 		display: inline-table;
// 		padding: 3px 15px;
// 		background-color: $color-4st;
// 		border-radius: 3px;
// 		outline: none;
// 		color: #fff;
// 		margin-top: 10px;
// 		border: none;
// 		font-size: rem-calc(14);
// 		font-weight: 700;
// 		cursor: pointer;
// 		&:hover{
// 			background-color: $color-3st;
// 		}
// 	}
// }

// .schema-view{
// 	display: flex;
// 	width: 100%;
// 	margin: 15px 0px;
// 	padding-top: 15px;
// 	background-color: #f4f4f4;
// 	._img{
// 		width: 100px;
// 		img{
// 			max-width: 100%;
// 			width: 100%;
// 		}
// 	}
// 	._content{
// 		width: 100%;
// 		width: calc(100% - 115px);
// 		padding-left: 15px;
// 		.schema-name{
// 			display: inline-block;
// 			width: 100%;
// 			color: $color-1st;
// 		}
// 		.schema-info{
// 			display: inline-block;
// 			width: 100%;
// 			padding: 0px;
// 			margin: 0px;
// 			span{
// 				display: inline-block;
// 				padding-right: 10px;
// 				&.schema-pricerange{
// 					color: $color-2st;
// 				}
// 				&.schema-telephone{
// 					color: $color-4st;
// 				}
// 			}
			
// 		}
// 	}
// }

// table{
// 	border: 1px solid #ddd;
// }

// .description{
// 	display: inline-block;
// 	width: 100%;
// 	padding: 15px;
// 	margin: 15px 0px;
// 	border: 1px dashed $color-2st;
// 	border-radius: 5px;
// 	background-color: #f4f4f4;
// }
// .title-sidebar{
// 	text-align: left;
// 	@include headings{
// 		font-weight: $font-bold;
// 		text-transform: uppercase;
// 		font-size: rem-calc(14);
// 		display: inline-block;
// 		background: rgb(255,255,255);
// 		background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(221,221,221,1) 100%);
// 		position: relative;
// 		margin: 0px;
// 		padding: 12px 15px;
// 		// color: #fff;
// 		display: inline-block;
// 		width: 100%;
// 	}
// }


// .title-other{
// 	font-weight: $font-bold;
// 	font-size: rem-calc(24);
// 	display: inline-block;
// 	color: $color-1st;
// 	display: inline-block;
// 	width: 100%;
// 	margin: 15px 0px;
// 	margin-top: 10px;
// 	padding-bottom: 10px;
// 	border-bottom: 1px solid #ccc;
// }

// .title-post{
// 	// font-size: rem-calc(32);
// 	// display: inline-block;
// 	// width: 100%;
// 	// border-bottom: 1px dotted rgba(0,0,0,.2);
// 	// margin-bottom: 10px;
// 	// padding-bottom: 5px;
// }

// .blog-ct{
// 	// display: inline-table;
// 	// width: 100%;
// 	// padding-bottom: 30px;
// 	// font-family: 'times new roman', times;
// 	// font-size: rem-calc(20);
// }

// .schema-view{
// 	display: flex;
// 	width: 100%;
// 	margin-bottom: 15px;
// 	margin-top: 30px;
// 	padding-top: 15px;
// 	border-top: 1px dotted #eee;
// 	._img{
// 		width: 100px;
// 		img{
// 			max-width: 100%;
// 			width: 100%;
// 		}
// 	}
// 	._content{
// 		width: 100%;
// 		width: calc(100% - 115px);
// 		padding-left: 15px;
// 		.schema-name{
// 			display: inline-block;
// 			width: 100%;
// 			color: $color-2st;
// 			font-size: rem-calc(17);
// 			font-weight: 500;
// 		}
// 		.schema-info{
// 			display: inline-block;
// 			width: 100%;
// 			padding: 0px;
// 			margin: 0px;
// 			font-size: rem-calc(15);
// 			font-weight: 300;
// 			color: #666;
// 			span{
// 				display: inline-block;
// 				padding-right: 10px;
// 				&.schema-pricerange{
// 					color: #f00;
// 				}
// 				&.schema-telephone{
// 					color: $color-3st;
// 					font-weight:500;
// 				}
// 			}
			
// 		}
// 	}
// }

// @media(max-width: 991px){
// 	.list-service{
// 		display: none;
// 	}
// 	// .schema-view{
// 	// 	._content{
// 	// 		.schema-info{
// 	// 			span{
// 	// 				width: 100%;
// 	// 				margin-right: 0px;
// 	// 			}
// 	// 		}
// 	// 	}
// 	// }
// 	// .schema-view{
// 	// 	._content{
// 	// 		.schema-info{
// 	// 			span{
// 	// 				width: 100%;
// 	// 				margin-right: 0px;
// 	// 			}
// 	// 		}
// 	// 	}
// 	// }
// 	.title-main{
// 		margin-bottom: 30px;
// 		@include headings{
// 			font-size: rem-calc(20);
// 			text-transform: uppercase;
// 		}
// 	}
// 	.title-post{
// 		font-size: rem-calc(17);
// 	}
// 	@media(min-width: 576px){
// 	}
// }


