.group-pro-main{
    padding-top: 30px;
}
.pro-main--item{
    position: relative;
    overflow: hidden;
    background: #fff;
    z-index: 0;
    margin-bottom: 5px;
    width: 50%;
    width: calc(50% - 2.5px);
    margin-right: 5px;
    // border: 1px solid rgba(0,0,0,.1);
    // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
    // border-radius: 5px;
    // .code_new {
    //     position: absolute;
    //     color: #fff;
    //     background-color: green;
    //     display: inline-block;
    //     padding: 2px 5px;
    //     bottom: 5px;
    //     right: 10px;
    //     font-size: 12px;
    //     border-radius: 2px;
    //     z-index: 9;
    // }
    // .tip__new {
    //     position: absolute;
    //     color: #fff;
    //     background-color: green;
    //     display: inline-block;
    //     padding: 2px 10px;
    //     top: 10px;
    //     left: 10px;
    //     font-size: 13px;
    //     border-radius: 2px;
    //     text-transform: capitalize;
    //     z-index: 9;
    //     font-weight: $font-bold;
    // }
    .add{
        position: absolute;
        color: #fff;
        background-color: $color-2st;
        display: inline-block;
        padding: 2px 10px;
        bottom: 10px;
        left: 10px;
        font-size: 13px;
        border-radius: 2px;
        text-transform: capitalize;
        z-index: 9;
    }
    .pm-item__img{
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
            display: block;
            max-width: 100%;
            margin: 0 auto;
            border-radius: 5px;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
        }
    }
    .pm-item__cap{
        padding: 15px 10px;
        border: 1px dashed rgba(0,0,0,.2);
        border-radius: 5px;
        margin-top: 5px;
        @include headings{
            margin: 0px;
            font-size: rem-calc(18);
            font-weight: $font-regular;
            height: 45px;
            text-overflow: ellipsis;
            overflow: hidden;
            a{
                color: $color-1st;
                &:hover{
                    color: $color-2st;
                    text-decoration: none;
                }
            }
        }
        .item-cap__desc{
            padding-top: 5px;
            .detail-pro__price{
                font-size: rem-calc(18);
                font-weight: $font-medium;
                color: $color-3st;
                small{
                    margin-left: 5px;
                    text-decoration: line-through;
                    color: #888;
                    font-weight: 400;
                }
            }
        }
    }
    &:hover{
        img{
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
    &:nth-child(2n + 2){
        margin-right: 0px;
    }
}
@media(min-width: 992px){
    // .pro-ly-3{
    //     .pro-main--item{
    //         width: 33.33%;
    //         width: calc(33.33% - 10px);
    //         margin-right: 15px;
    //         margin-bottom: 15px;
    //         .pm-item__img{
    //             height: 200px;
    //             img{
    //                 max-height: 170px;
    //             }
    //         }
    //         &:nth-child(2n + 2){
    //             margin-right: 15px;
    //         }
    //         &:nth-child(3n + 3){
    //             margin-right: 0px;
    //         }
    //     }
    // }
    .pro-ly-4{
        .pro-main--item{
            width: 25%;
            width: calc(25% - 11.25px);
            margin-right: 15px;
            margin-bottom: 15px;
            &:nth-child(2n + 2){
                margin-right: 15px;
            }
            &:nth-child(4n + 4){
                margin-right: 0px;
            }
        }
    }
    // .pro-ly-5{
    //     .pro-main--item{
    //         width: 20%;
    //         width: calc(20% - 12px);
    //         margin-right: 15px;
    //         margin-bottom: 15px;
    //         .pm-item__img{
    //             height: 200px;
    //             img{
    //                 max-height: 170px;
    //             }
    //         }
    //         &:nth-child(2n + 2){
    //             margin-right: 15px;
    //         }
    //         &:nth-child(5n + 5){
    //             margin-right: 0px;
    //         }
    //     }
    // }
}
