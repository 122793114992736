// #banner{
//     .owl-nav{
//         position: absolute;
//         bottom: 50%;
//         margin-top: -30px;
//         left: 0;
//         right: 0;
//         .owl-prev, .owl-next {
//             position: absolute;
//             height: 60px;
//             width: 60px;
//             text-align: center;
//             margin: 0px;
//             color: inherit;
//             z-index: 100;
//             background-color: transparent !important;
//             display: block;
//             border-radius: 100%;
//             border: 1px solid transparent !important;
//             outline: none;
//             opacity: 1;
//             i {
//                 font-size: 24px;
//                 color: #000;
//                 &:hover{
//                     color: #0179cf;
//                 }
//             }
//         }
//         .owl-prev {
//             left: 0px;
//         }
//         .owl-next {
//             right: 0px;
//         }
//     }
// }
// @media(max-width: 992px){
//     .owl-carousel{
//         .owl-nav{
//             display: none;
//         }
//     }
// }
// dot banner
#banner{
    .owl-dots{
        position: absolute;
        left: 50%;
        bottom: 30px;
        font-size: 0;
        letter-spacing: 0;
        width: 300px;
        margin-left: -150px;
        text-align: center;
        .owl-dot{
            outline: none !important;
            span{
                width: 12px;
                height: 12px;
                background-color: rgba(255,255,255,5);
                border-radius: 12px;
                border: 2px solid transparent;
                margin: 0px 5px;
            }
        }
        .active{
            span{
                background-color: rgba(0,0,0,.5) !important;
            }
        }
    }
}
@media(max-width: 767px){
    #banner{
        .owl-dots{
            bottom: 10px;
        }
    }
}
