// Menu trái
ul.left-sidebar{
    width: 100%;
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: inline-table;
    li{
    	width: 100%;
    	float: left;
    	a{
    		color: $color-1st;
		    padding: 10px 0px;
		    padding-left: 25px;
		    display: inherit;
		    position: relative;
		    border-bottom: 1px solid rgba(0,0,0,.1);
		    &:before{
				content: "";
				position: absolute;
				left: 0px;
				top: 20px;
				width: 5px;
				height: 5px;
				background-color: $color-2st;
				border-radius: 100%;
			}
		    &:hover{
		    	color: $color-2st;
		    }
    	}
    	// .cate-menu-sub2{
		//     padding: 15px 0px;
		//     margin: 0px;
		//     list-style: none;
		//     display: none;
		//     background-color: #ffffff;
		//     border-bottom: 1px solid rgba(0,0,0,.1);
		//     li{
		//     	width: 100%;
		//     	a{
		//     		// padding-right: 0px;
		//     		// font-weight: $font-regular;
		//     		// background-color: #fff;
		//     		color: $color-1st;
		//     		padding: 0px 15px;
		//     		font-size: rem-calc(14);
		//     		border: none;
		//     		&:hover{
		//     			color: $color-3st;
		//     		}
		//     	}
		//     	&:last-child{
		//     		a{
		//     			border: none;
		//     		}
		//     	}
		//     }
    	// }
    	&.active{
    		a{
    			color: $color-3st;
    		}
    		// .cate-menu-sub2{
    		// 	display: inline-block;
    		// }
    	}
    }
}


@media (max-width: 991px){
	#menu{
	    position: fixed;
	    padding: 0;
	    -ms-transform: translateX(280px);
	    -webkit-transform: translateX(280px);
	    transform: translateX(280px);
	    background-color: $color-2st;
	    min-height: 100%;
	    height: 100vh;
	    z-index: 99999;
	    width: 280px;
	    right:0;
	    top: 0;
	    transition: all 0.3s ease;
	    opacity: 1;
	    overflow-x: scroll;
	    // box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.2) inset;
	}
	#menu ul {
	  	list-style: none;
	  	margin-top: 0;
	  	padding: 0;
	  	li{
	  		width: 100%;
		  	a{
		  		color: #fff;
			    display: inline-block;
			    width: 100%;
			    padding: 0px 40px;
			    padding-left: 15px;
			  	text-decoration: none;
			  	transition: all 0.3s ease;
			  	line-height: 40px;
    			border-bottom: 1px solid rgba(255, 255, 255, .3);
    			font-weight: $font-bold;
    			position: relative;
    			text-transform: uppercase;
			    span{
			    	position: absolute;
				    right: 0;
				    width: 40px;
				    height: 40px;
				    line-height: 40px;
				    text-align: center;
				    top: 0px;
				    border-left: 1px solid rgba(255,255,255,.3);
				    font-size: rem-calc(18);
			    }
			    &.active+ul{
					display: block;
			    }
		  	}
		  	ul{
		  		margin-top: 0;
		  		visibility: visible;
  				display: none;
  				position: relative;
  				opacity: 1;
  				left: 0px;
  				li{
  					border-bottom: none;
  					a{
  						color: #fff;
  						padding-left: 30px;
  						font-weight: $font-regular;
  						text-transform: none;
  						i{
  							display: none;
  						}
  					}
  					ul{
  						display: block !important;
  						li{
  							a{
  								padding-left: 45px;
  							}
  						}
  					}
  				}
		  	}
	  	}
	  	a{
	  		span[class*='fa-caret'] { float: right; }
	  		&.active,
	  		&:hover{
				background: rgba(0, 0, 0, 0.04);
	  		}
	  	}
	}

	.button-res{
		.showmenu{
			display: block;
			padding:0px 15px;
			// position: fixed;
			z-index: 555;
			padding-bottom: 3px;
			left: 0;
			right: 0;
			top:0;
			background-color: #fff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.logo-mobile{
			display: inline-block;
			float: left;
			padding-top: 5px;
			img{
				height: 40px;
			}
		}
		.symbol_menu{
			display: inline-block;
			font-size: 30px;
			color: $color-2st;
		}
	}
}
}
@media (min-width: 992px){
	.logo-mb{
		display: none;
	}
	.header-main{
		position: relative;
		z-index: 99;
		background-color: #fff;
		padding: 5px 0px;
		.logo{
			img{
				height: 70px;
			}
		}
		.hotline{
			position: relative;
			padding-left: 50px;
			font-size: rem-calc(20);
			line-height: 1;
			&:before{
				content: "";
				font-family: fontAwesome;
				position: absolute;
				left: 0px;
				top: 0px;
				width: 40px;
				height: 40px;
				background-color: $color-2st;
				border-radius: 100%;
				text-align: center;
				line-height: 40px;
				color: #fff;
			}
			a{
				font-weight: $font-bold;
				color: $color-3st;
			}
			span{
				width: 100%;
				font-size: rem-calc(14);
				display: inline-block;
			}
		}
	}
	
	/*main menu*/
	#menu{
		width: 100%;
	}
	ul.main-menu{
		padding: 0px;
		margin: 0px;
		list-style: none;
		width: 100%;
		li{
			position: relative;
			float: left;
			a{
				color: $color-1st;
				text-transform: uppercase;
				display: inline-block;
				padding: 0px 25px;
				font-size: rem-calc(16);
				font-weight: $font-medium;
				line-height: 40px;
				i{
					font-size: rem-calc(20);
					color: $color-2st;
				}
				svg{
					width: 25px;
					margin-top: 5px;
				}
				&:hover{
					color: $color-2st;
				}
			}
			&:hover .main-menu-sub1{
				opacity: 1;
				visibility: visible;
			}
			.main-menu-sub1{
				position: absolute;
				left: 0px;
				top: 100%;
				background-color: #fff;
				width: 240px;
				z-index: 999;
				display: block;
				opacity: 0;
				visibility: hidden;
				padding: 15px 0px;
				border-radius: 15px;
				box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
				li{
					width: 100%;
					display: inline-block;
					position: relative;
					a{
						color: $color-1st;
						padding: 8px 15px;
						line-height: 18px !important;
						display: inline-block;
						width: 100%;
						font-weight: $font-regular;
						border-right: none;
						text-transform: none;
						text-transform: capitalize;
						&:hover{
							background-color: transparent;
							color: $color-2st;
						}
						i{
							float: right;
						}
					}
					// .main-menu-sub2{
					// 	padding: 15px 0px;
					// 	position: absolute;
					// 	left: 100%;
					// 	top: 0px;
					// 	background-color: #fff;
					// 	border-left: 2px solid $color-2st;
					// 	width: 280px;
					// 	z-index: 999;
					// 	display: block;
					// 	opacity: 0;
					// 	visibility: hidden;
					// 	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
					// }
					// &:hover .main-menu-sub2{
					// 	opacity: 1;
					// 	visibility: visible;
					// }
				}
			}
		}
	}/*end main menu*/
}
