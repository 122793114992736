footer{
	background-color: $color-2st;
	font-weight: $font-regular;
	margin-top: 40px;
}
.top-footer{
	padding: 40px 0px 30px 0px;
	color: rgba(255,255,255,.8);
	.tit-footer{
		display: inline-block;
		width: 100%;
		font-weight: $font-bold;
		padding-bottom: 15px;
		color: #fff;
		text-transform: uppercase;
	}
	.list-footer{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			line-height: 1.7;
			a{
				display: inline-block;
				color: rgba(255,255,255,.8);
				padding-left: 20px;
				position: relative;
				text-transform: capitalize;
				&:before{
					content: "";
					position: absolute;
					left: 0px;
					top: 10px;
					width: 5px;
					height: 5px;
					background-color: $color-4st;
					border-radius: 100%;
				}
				&:hover{
					color: $color-4st;
				}
			}
		}
		&.top-line{
			margin-top: 20px;
			border-top: 1px solid rgba(255,255,255,.3);
			padding-top: 20px;
		}
	}
	.payment-method{
		img{
			background-color: #fff;
			margin-bottom: 5px;
			border-radius: 3px;
		}
	}
}
.back-to-top {
	content: "";
	position: fixed;
	right: 15px;
	bottom: 15px;
    width: 40px;
    height: 40px;
    background-color: gray;
    border-radius: 100%;
    text-align: center;
    display: inline-table;
    opacity: .7;
    i{
    	color: $color-1st;
    	font-size: rem-calc(32);
    	line-height: 40px;
    }
    &:hover{
    	opacity: 1;
    }
}
@media(max-width: 767px){
	.top-footer{
		.tit-footer{
			margin-top: 30px;
		}
	}
}