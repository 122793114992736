

.btn-viewall{
	display: inline-block;
	padding: 10px 30px;
	color: #fff;
	font-weight: $font-regular;
	outline: none;
	font-size: rem-calc(18);
	margin: 15px 0px;
	margin-top: 30px;
	border-radius: 30px;
	background-color: $color-2st;
	border: none;
	&:hover{
		background-color: $color-3st;
		color: #fff;
	}
}